.icon {
    transition: 0.3s !important;
}

.button {
    width: 7em;
}

.expandedIcon {
    transform: rotate(180deg);
}

.expandTab {
    padding: 10px;
}

.title {
    font-size: 1.3em;
}