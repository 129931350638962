.mainAccordionDetails {
    display: flex !important;
    flex-direction: column !important;
    padding: 0 !important;
}

.lessonAccordion {
    padding-left: 10px;
}

.lessonAccordion::before {
    content: "";
    display: none;
}

.lessonAccordion > * {
    border: 0;
    padding-left: 10px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.fileAccordion {
    padding: 0;
    padding-left: 10px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.fileAccordion:hover {
    border-left: 1px solid #2196f3;
}

.title {
    padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    font-weight: 900;
}

.videoContainer, .documentContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start !important;
    width: 100%;
    height: 5em;
}

.selected {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.iframe, .filePreview, .video {
    width: 7em;
    margin-right: 1em;
    aspect-ratio: 16/9;
    overflow: hidden;
}

.filePreview {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.05);
}

.simple {
    box-shadow: none;
}
