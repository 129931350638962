.get-started-card {
    padding: 70px 45px;
    /* background-image: linear-gradient(to top,rgba(0,0,0,.8) 0,rgba(0,0,0,0) 60%,rgba(0,0,0,.8) 100%); */
}

.get-started-card-text {
    width: 100%;
    padding: 75px 0 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    word-break: break-all;
}

.get-started-card-form {
    width: 100%;
    display: flex;
    justify-content: center;
}

.get-started-card-background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-size: cover;
}