.container {
    position: relative;
    display: flex;
    overflow: hidden;
    aspect-ratio: 16/9;
    background-color: black;
}

.player {
    width: 100%;
    align-self: center;
}

.container:hover > .controls {
    transform: translateY(0);
    opacity: 1;
}

.controls {
    width: 100%;
    bottom: 0;
    position: absolute;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), transparent);
    transform: translateY(100%);
    transition: transform 0.07s ease-in-out, opacity 0.5s;
    opacity: 0;
    display: flex;
    flex-direction: column;
}

.loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

/* .slider {
    width: 95%;
    padding: 5px;
}

.volume {
    display: flex !important;
    align-items: center !important;
}

.volumeBtn {
    color: #fff !important;
    flex-grow: 1;
}

.volume:hover > .volumeSliderContainer {
    width: 50px;
    transition-delay: 0s;
    overflow: visible;
    padding: 0 6px;
}

.volumeSliderContainer {
    width: 0;
    overflow: hidden;
    position: relative;
    transition: .2s;
    transition-delay: 1s;
    display: flex;
    align-items: center;
    padding: 0;
}

.volumeSlider {
    width: 50px;
    color: white !important;
} */

/* .timing {
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
} */