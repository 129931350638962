.pre-register-form {
    max-width: 450px;
    width: 90%;
}

.pre-register-form > * {
    margin-bottom: 5px !important;
}

/* @media (max-width: 768px) {
    .pre-register-form {
        width: 90%;
    }
} */