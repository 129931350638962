.register-body {
    margin: 0 auto;
    margin-bottom: 5em;
    max-width: 450px;
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 5px;
}

.register-content {
    padding: 60px;
}

@media only screen and (max-width: 500px) {

    .register-body {
        margin: none;
        max-width: max-content;
        border-radius: 0px;
    }

    .register-content {
        padding: 10px;
    }

    .register-wrapper {
        background: black;
    }
}