
.module, .header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.header {
    margin-left: 0;
    margin-right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
}

.lessons {
    max-height: 0;
    transition: 0.2s;
    overflow: hidden;
}

.lessons[data-expanded="true"] {
    max-height: 25em;
    overflow-y: scroll;
}

.lessons > * {
    margin: 10px;
}
