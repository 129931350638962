.cardContainer {
    position: relative;
    transition: .6s !important;
    transition-delay: .25s, .15s !important;
    overflow: visible;
    z-index: 500;
}

.card {
    height: 15vw;
    width: 25vw;
    max-width: 300px;
    max-height: 200px;
    min-width: 10em;
    min-height: 7em;
}

.cardContainer:hover, .cardContainer:focus {
    transform: scale(1.2) translateY(-25%);
    height: 100% !important;
    z-index: 5000;
}

.cardContainer:hover > .cardModal, .cardContainer:focus > .cardModal {
    opacity: 1;
    visibility: visible !important;
    height: 7em;
}

.cardModal {
    position: absolute;
    visibility: hidden !important;
    top: 95%;
    left: 0;
    background: rgb(27, 27, 27) !important;
    opacity: 0;
    transition: .4s ease-in !important;
    transition-delay: .05s, 1s !important;
    width: 25vw;
    min-width: 10em;
    max-width: 300px;
    max-height: 200px;
    z-index: 5000;
    height: 0;
}