:root {
    --muted: #353535;
    --gray-blue: #34495e;
    --dark-gray-blue: #2c3e50;
}

h1 {
    margin-bottom: 28px;
}

h2 {
    margin-bottom: 18px;
}

button:disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
}

table {  
    text-align: center;
}

thead {
    border-bottom: 1px solid black;
}

th {
    padding: 5px 0;
}

.table-text-dots {
    max-width: 10em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nocursor {
    cursor: none;
}

.remove-a {
    color: black;
    text-decoration: none;
}

.remove-a:hover {
    color: black;
}

.children-margin-bottom > * {
    margin-bottom: 1em;
}

.title {
    font-weight: 700;
}

.header {
    font-weight: 700;
}

.font-light {
    font-weight: lighter;
}

.text-event {
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
}

.overflow-hidden {
    overflow: hidden;
}

.break-word {
    word-wrap: break-word;
}

.form-label {
    font-weight: bold;
}

.text-center, *[text-center] {
    text-align: center;
}

.rounded-right {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rounded-left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}

.margin-0 {
    margin: 0;
}

.margin-bottom {
    margin-bottom: 1em;
}

.hide {
    display: none !important;
}

.show {
    display: initial;
}

.flex {
    display: flex;
}

.inline-flex {
    display: inline-flex;
}

.flex-wrap, *[wrap] {
    flex-wrap: wrap;
}

.flex-nowrap, *[nowrap] {
    flex-wrap: nowrap;
}

.align-center {
    align-items: center;
}

.jcc {
    justify-content: center;
}

.jcsb {
    justify-content: space-between !important;
}

.jcsa {
    justify-content: space-around;
}

.jcse {
    justify-content: space-evenly;
}

.jsstart {
    justify-content: flex-start;
}

.jsend {
    justify-content: flex-end;
}

.fdrow {
    flex-direction: row;
}

.fdcolumn {
    flex-direction: column;
}

.w100 {
    width: 100%;
}

.wauto {
    width: auto;
}

.fit-content {
    width: fit-content;
}

.height1 {
    height: 1em;
}

.height2 {
    height: 2em;
}

.height3 {
    height: 3em;
}

.height4 {
    height: 4em;
}

.flex1 {
    flex: 1;
}

.flex2 {
    flex: 2;
}

.flex3 {
    flex: 3;
}

.icon {
    cursor: pointer;
}

.icon:hover {
    filter: brightness(0.5);
    transition: .2s ease-in-out;
}

.nonclick {
    pointer-events: none;
}

.table-cell {
    display: table-cell;
}