.main-header {
    width: 100%;
    height: 56px;
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 10%, rgba(0, 0, 0, 0)); */
    transition: .2s;
}
