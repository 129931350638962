.course-landing-page-header {
    width: 100%;
    background-color: cadetblue;
    padding: 2.5em;
    display: inline-flex;
    justify-content: space-between;
}

.course-landing-page-body {
    width: 100%;
    /* padding: 10px; */
    /* display: flex;
    align-items: center;
    flex-direction: column; */
}

.course-landing-page-title {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 3em 0 0;
}

.course-landing-page-title > *{
    font-weight: bolder;
}

.course-landing-page-content {
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.course-landing-page-content > div {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.course-landing-page-content #left {
    margin-bottom: 1em;
}
