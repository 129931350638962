.login-body {
    margin: 0 auto;
    margin-bottom: 5em;
    max-width: 450px;
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 5px;
}

.login-content {
    padding: 60px;
}

@media only screen and (max-width: 500px) {

    .login-body {
        margin: none;
        max-width: max-content;
        border-radius: 0px;
    }

    .login-content {
        padding: 10px;
    }

    .login-wrapper {
        background: black;
    }
}