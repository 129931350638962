.lessonsContent > * {
    margin: 5px;
}

.formControl {
    width: 100%;
    border-radius: 7px;
    margin: 0;
}

.isApproved {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bolder;
}

.isApproved[approved="true"] {
    color: #1e8e3e;
}

.isApproved[approved="false"] {
    color: #d93025;
}