.preview-modal {
    width: 600px;
    height: 500px;
    background-size: cover;
}

.preview-thumb {
    width: 100%;
    height: fit-content;
}

.preview-lesson > h3 {
    font-size: 24px;
    font-weight: 700;
}

.preview-files {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    padding: 10px;
}

.preview-description > p {
    font-size: 18px;
}
