body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.navbar-custom {
  background-color: #ececf1;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0 10px 0 0;
  margin-left: 240px;
  min-height: 70px;
}

.topbar {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
}

.topbar .topbar-left {
  background: #1893d6;
  float: left;
  text-align: center;
  height: 70px;
  position: relative;
  width: 240px;
  z-index: 1;
}


.content-page{
  width: 100%;
  padding-top: 150px;
  background: #f8f8fa;
}

.pagination {
  /* display: inline-block; */
  padding-left: 15px;
  padding-right: 15px;
}

.pagination li {
  display: inline-block;
  padding-left: 10px;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border:1px solid lightgrey;
  cursor: pointer;
}



.pagination li.previous:not(.disabled) {
  background-color: #198754;
  color:white
}

.pagination li.previous:not(.disabled) a {
  color:white
}

.pagination li.next:not(.disabled) {
  background-color: #198754;
}

.pagination li.next:not(.disabled) a {
  color:white
}

.pagination li.active {
  background-color: #198754;
  color:white
}

.pagination li.active a {
  color:white
}

.tab {
  min-height: 50px;
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;border-color: #dcdcdc #dcdcdc white;
  text-align: center;
  font-size: 1.2rem;
  color: black;
  margin-right: 2px
}


.opcao_autocomplete{
  padding: 10px 10px 10px 10px;
  background-color: white;
  cursor: pointer;

}
.opcao_autocomplete:hover{
  background-color: #f2f2f2;
}

.col-form-label{
  margin-top: 10px;
  font-weight: bold;
  padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}

.header-title{
  font-weight: bold;
}

.map2{
  position: relative !important;
  height: 100%;
}

.hr_show{
  display: block;
}


@media (max-width:992px){
  .hr_show{
    display: none;
  }
}


@media (max-width:576px){
  .hr_show{
    display: block;
  }
}



